
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import PaymentRepository from "@/api/repositories/PaymentRepository";

import PaymentMethod from "@/models/PaymentMethod";
import {ROUTE_PROFILE} from "@/router/routes";

@Component
export default class CompanyBalanceComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private companyId!: string;

  @Prop({required: true})
  private isLoading!: boolean;

  @Prop({default: null})
  private income?: number | null;

  private paymentMethods: PaymentMethod[] | null = null;

  private get showPaypalButton() {
    if(!this.paymentMethods) return false;
    const paypalMethod = this.paymentMethods.find((m) => m.type === 'paypal');
    if(paypalMethod || !this.income) return false;
    return this.income > 0;
  }

  /**
   * loads all payment methods
   * @private
   */
  private async loadPaymentMethods() {
    try {
      const methodResponse = await PaymentRepository.getPaymentMethods(this.$route.params.id);
      this.paymentMethods = methodResponse.data.map((m: PaymentMethod) => PaymentMethod.parseFromObject(m));
    } catch (_) {
      this.paymentMethods = [];
    }
  }

  /**
   * loads payment methods of the user, initializes braintree for paypal
   * @private
   */
  private async created() {
    await this.loadPaymentMethods();
  }

  private onRouteToPaymentClicked() {
    this.$router.push({name: ROUTE_PROFILE, params: {id: this.$route.params.id}, query: {'state': 'PAYMENT'}});
  }

  private convertToCurrency(value: number) {
    return this.$formatCurrency(value);
  }
}
