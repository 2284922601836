import Parseable from "@/misc/Parseable";
import {PaymentType} from "@/enum/PaymentType.enum";
import CreditCardModel from "@/models/CreditCard.model";

export default class PaymentMethod extends Parseable {
    public id!: string;
    public isDefault!: boolean;
    public paypal!: any;
    public type!: PaymentType;
    public card!: CreditCardModel;

    parseToObject(): any {
        return {...this};
    }

    /**
     * parses an object to a news object
     * @param object
     */
    public static parseFromObject(object: any): PaymentMethod {
        const paymentMethod = new PaymentMethod();
        Object.assign(paymentMethod, object);
        return paymentMethod;
    }
}